import { forwardRef } from "react";

import type { Props as BaseImageProps } from "~/components/common/base-image";
import { GalleryItem } from "~/components/image-gallery/gallery-item";

import type { StyledProps } from "./styles";
import { ArticleImageContainer, DescriptionContainer, ImageContainer, StyledImage } from "./styles";

export type Props = {
  className?: string;
  imageProps: BaseImageProps;
  title: string;
} & Partial<PropsFromStyledProps<StyledProps>>;

export const ArticleImage = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      imageProps,
      responsiveDescriptionVariants = { desktopUp: "show", mobileUp: "show", tabletUp: "show" },
      responsiveImageVariants = { desktopUp: "normal", mobileUp: "normal", tabletUp: "normal" },
      title = "",
    },
    reference,
  ) => (
    <GalleryItem src={imageProps.src} title={title}>
      {({ open, ref }) => (
        <ArticleImageContainer
          $responsiveDescriptionVariants={responsiveDescriptionVariants}
          $responsiveImageVariants={responsiveImageVariants}
          className={className}
          ref={reference}
        >
          <ImageContainer onClick={open}>
            <StyledImage ref={ref} {...imageProps} />
          </ImageContainer>

          <DescriptionContainer>{title}</DescriptionContainer>
        </ArticleImageContainer>
      )}
    </GalleryItem>
  ),
);

ArticleImage.displayName = "ArticleImage";
