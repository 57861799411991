import styled from "@emotion/styled";
import { notEmpty, parseDate } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { JsonLd } from "react-schemaorg";
import type { VideoObject } from "schema-dts";

import { ArticleSpeechSkipContainer } from "~/components/article/article-speech";
import type { ContentSchemaRenderProps } from "~/components/schema-render/content";
import { useRenderContext } from "~/components/schema-render/content";
import type { Props as SCMPYouTubeVideoPlayerProps } from "~/components/scmp-youtube-video/scmp-youtube-video-player";
import { durationToISOString, getAbsoluteUrl } from "~/lib/utils";
import type { scmpYoutubeContent$key } from "~/queries/__generated__/scmpYoutubeContent.graphql";

import { StyledSCMPYoutubeVideo } from "./styles";

export type ExtraProps = {
  enableAutoPlay?: boolean;
  gaActionPrefix?: SCMPYouTubeVideoPlayerProps["gaActionPrefix"];
  skipAdInfo?: boolean;
};

export type Props = {
  "data-duration"?: string;
  "data-hero-video"?: string;
  "data-nid"?: string;
  "data-poster"?: string;
  "data-poster-lg"?: string;
  "data-poster-sm"?: string;
  "data-video-source"?: string;
  "data-youtube-id"?: string;
  reference: scmpYoutubeContent$key;
  src?: string;
  title?: string;
} & ExtraProps &
  ContentSchemaRenderProps;

const Component: FunctionComponent<Props> = ({
  enableAutoPlay = true,
  gaActionPrefix,
  index,
  reference: reference_,
  skipAdInfo,
  src,
  title,
  ...attribs
}: Props) => {
  const content = useFragment(
    graphql`
      fragment scmpYoutubeContent on Content {
        urlAlias
        publishedDate
      }
    `,
    reference_,
  );

  const { advertising } = useRenderContext();

  const duration = attribs["data-duration"];
  const thumbnailSource = attribs["data-poster"];
  const imageSource = attribs["data-poster-lg"];
  const isHeroVideo = (attribs["data-hero-video"] ?? "0") === "1";
  const mobileImageSource = attribs["data-poster-sm"];
  const youtubeVideoId = attribs["data-youtube-id"];
  const entityId = attribs["data-nid"];

  const handleRenderVideoSchema = useCallback(() => {
    const [minutes, seconds] = duration?.split(":") ?? [];

    const thumbnailUrls = [thumbnailSource, imageSource, mobileImageSource].filter(notEmpty);
    const uploadDate = content?.publishedDate
      ? parseDate(content?.publishedDate).toISOString()
      : null;
    // refs: https://developers.google.com/search/docs/appearance/structured-data/video#video-object
    if (!title || thumbnailUrls.length === 0 || !uploadDate) return false;
    return (
      <JsonLd<VideoObject>
        item={{
          "@context": "https://schema.org",
          "@type": "VideoObject",
          contentUrl: src,
          description: title,
          duration: durationToISOString(
            Number.parseInt(minutes ?? "0"),
            Number.parseInt(seconds ?? "0"),
          ),
          embedUrl: `${getAbsoluteUrl(content?.urlAlias)}`,
          name: title,
          thumbnailUrl: thumbnailUrls,
          uploadDate,
        }}
      />
    );
  }, [
    content?.publishedDate,
    content?.urlAlias,
    duration,
    imageSource,
    mobileImageSource,
    src,
    thumbnailSource,
    title,
  ]);

  const adInfo = useMemo(
    () => ({
      targeting: {
        ...advertising?.targeting,
      },
      zone: `${advertising?.zone ?? ""}/${advertising?.instream ?? "instream1"}`,
    }),
    [advertising?.instream, advertising?.targeting, advertising?.zone],
  );

  return (
    <ArticleSpeechSkipContainer>
      {handleRenderVideoSchema()}
      <StyledSCMPYoutubeVideo
        adInfo={skipAdInfo ? undefined : adInfo}
        duration={duration}
        enableAutoPlay={enableAutoPlay}
        entityId={entityId}
        gaActionPrefix={gaActionPrefix}
        imageSource={imageSource}
        index={index}
        isHeroVideo={isHeroVideo}
        mobileImageSource={mobileImageSource ?? imageSource}
        title={title}
        withFooter={{ variant: "default" }}
        youtubeVideoId={youtubeVideoId}
      />
    </ArticleSpeechSkipContainer>
  );
};

Component.displayName = "SCMPYoutube";
export const SCMPYoutube = styled(Component)``;
