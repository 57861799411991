import type { FunctionComponent } from "react";
import { useEffect } from "react";
import { graphql, useQueryLoader } from "react-relay";

import { ArticleSpeechSkipContainer } from "~/components/article/article-speech";
import { ClientSideSuspense } from "~/components/common/client-side-suspense";
import type { ContentSchemaRenderProps } from "~/components/schema-render/content";
import { ImageSizeStyle, useContentUtils } from "~/lib/hooks";
import { isValidUrl } from "~/lib/utils";
import type { digitalArchiveLinkCheckPathQuery } from "~/queries/__generated__/digitalArchiveLinkCheckPathQuery.graphql";

import { DigitalArchiveLinkImage } from "./digital-archive-link-image";
import { DigitalArchiveLinkUpdatedDate } from "./digital-archive-link-updated-date";
import {
  ImageContainer,
  LeftContainer,
  RightContainer,
  RootContainer,
  TitleContainer,
  TitleText,
  UpdatedDateContainer,
} from "./styles";

export const query = graphql`
  query digitalArchiveLinkCheckPathQuery($url: String!, $imageSize: String!) {
    checkPath(url: $url) {
      ...digitalArchiveLinkImageUrlPath
      ...digitalArchiveLinkUpdatedDateUrlPath
    }
  }
`;

export type DigitalArchiveLinkProps = {
  href?: string;
  index: number;
  schemaNode: SchemaNode;
};

type Props = {
  lazyload?: boolean;
} & ContentSchemaRenderProps &
  DigitalArchiveLinkProps;

export const DigitalArchiveLink: FunctionComponent<Props> = ({
  href = "",
  lazyload,
  schemaNode,
}) => {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<digitalArchiveLinkCheckPathQuery>(query);

  const { extractTextFromSchemaNodes } = useContentUtils();

  useEffect(() => {
    if (!isValidUrl(href)) return;

    const url = new URL(href);
    loadQuery({ imageSize: ImageSizeStyle["237x147"], url: url.pathname });

    return disposeQuery;
  }, [loadQuery, disposeQuery, href]);

  return (
    <ArticleSpeechSkipContainer>
      <RootContainer>
        <LeftContainer>
          <TitleContainer>
            <TitleText>{extractTextFromSchemaNodes(schemaNode)}</TitleText>
          </TitleContainer>
          <UpdatedDateContainer>
            <ClientSideSuspense>
              {queryReference && <DigitalArchiveLinkUpdatedDate queryReference={queryReference} />}
            </ClientSideSuspense>
          </UpdatedDateContainer>
        </LeftContainer>

        <RightContainer>
          <ImageContainer>
            <ClientSideSuspense>
              {queryReference && (
                <DigitalArchiveLinkImage lazyload={lazyload} queryReference={queryReference} />
              )}
            </ClientSideSuspense>
          </ImageContainer>
        </RightContainer>
      </RootContainer>
    </ArticleSpeechSkipContainer>
  );
};

DigitalArchiveLink.displayName = "DigitalArchiveLink";
