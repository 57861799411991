export const topic = {
  greaterBayAreaBusinessPropertyAndTech: {
    entityId: "513657",
    entityUuid: "d12d86bf-bb9c-4ef0-87db-7aaa1236ff49",
    name: "Greater Bay Area: Business, Property & Tech",
    routeType: "Topic",
    urlAlias: "/topics/greater-bay-area-business-property-tech",
  },
  greaterBayAreaComment: {
    entityId: "513656",
    entityUuid: "b7555083-05e3-4dd9-956f-cbefbd8edd3b",
    name: "Greater Bay Area: Comment",
    routeType: "Topic",
    urlAlias: "/topics/greater-bay-area-comment",
  },
  greaterBayAreaEducationAndMedical: {
    entityId: "513659",
    entityUuid: "1357a1ed-a9c2-408e-a551-6df3a07c4b68",
    name: "Greater Bay Area: Education & Medical",
    routeType: "Topic",
    urlAlias: "/topics/greater-bay-area-education-medical",
  },
  greaterBayAreaNewsAndPolitics: {
    entityId: "513654",
    entityUuid: "fa5900ef-cc10-4eeb-83c6-caeebb0bbafc",
    name: "Greater Bay Area: News & Politics",
    routeType: "Topic",
    urlAlias: "/topics/greater-bay-area-news-politics",
  },
  greaterBayAreaTravelAndLifestyle: {
    entityId: "513658",
    entityUuid: "371711e6-e1de-4532-873c-ee6a5aa75e11",
    name: "Greater Bay Area: Travel & Lifestyle",
    routeType: "Topic",
    urlAlias: "/topics/greater-bay-area-travel-lifestyle",
  },
  hongKongChiefExecutiveElection2022: {
    entityId: "511618",
    entityUuid: "f2025ab2-c284-42a4-83fe-aa21e7ba6054",
    name: "Hong Kong chief executive election 2022",
    routeType: "Topic",
    urlAlias: "/topics/hong-kong-chief-executive-election-2022",
  },
  hongKongCouncilElection2021: {
    entityId: "510368",
    entityUuid: "0aa8899e-5015-4a4e-b71b-375a70190990",
    name: "Hong Kong Legislative Council election 2021",
    routeType: "Topic",
    urlAlias: "/topics/hong-kong-legislative-council-election-2021",
  },
} as const;
