import styled from "@emotion/styled";
import { fontMerriweather } from "@product/scmp-sdk";

import { ContentShareWidget } from "~/components/content/content-share-widget";

import QuoteIcon from "./icon-quote.svg";

export const StyledQuoteIcon = styled(QuoteIcon)``;

export const StyledFigure = styled.figure`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;

  max-inline-size: 295px;
  margin-block: 2px 28px;
  margin-inline: auto;

  font-weight: 900;
  font-size: 24px;
  font-family: ${fontMerriweather};
  line-height: 31px;

  ${props => props.theme.breakpoints.up("tablet")} {
    max-inline-size: 576px;
    margin-block: 12px 32px;
  }
`;

export const StyledBlockquote = styled.blockquote`
  margin: 0;
  margin-block-start: 12px;
`;

export const StyledFigcaption = styled.figcaption`
  color: #999999;
`;

export const StyledFooter = styled.footer`
  margin-block-start: 12px;

  border-block-start: 4px solid #001246;
`;

export const StyledContentShareWidget = styled(ContentShareWidget)`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
`;
