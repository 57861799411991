/**
 * @generated SignedSource<<4d12e95909e5f0d97f85413fe931319b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type digitalArchiveLinkCheckPathQuery$variables = {
  imageSize: string;
  url: string;
};
export type digitalArchiveLinkCheckPathQuery$data = {
  readonly checkPath: {
    readonly " $fragmentSpreads": FragmentRefs<"digitalArchiveLinkImageUrlPath" | "digitalArchiveLinkUpdatedDateUrlPath">;
  } | null | undefined;
};
export type digitalArchiveLinkCheckPathQuery = {
  response: digitalArchiveLinkCheckPathQuery$data;
  variables: digitalArchiveLinkCheckPathQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "imageSize"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "url"
},
v2 = [
  {
    "kind": "Variable",
    "name": "url",
    "variableName": "url"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v5 = {
  "kind": "InlineFragment",
  "selections": (v4/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "digitalArchiveLinkCheckPathQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "checkPath",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "digitalArchiveLinkImageUrlPath"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "digitalArchiveLinkUpdatedDateUrlPath"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "digitalArchiveLinkCheckPathQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "checkPath",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "item",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "images",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "fields": [
                                  {
                                    "kind": "Variable",
                                    "name": "style",
                                    "variableName": "imageSize"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "filter"
                              }
                            ],
                            "concreteType": "ImageStyle",
                            "kind": "LinkedField",
                            "name": "style",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedDate",
                        "storageKey": null
                      }
                    ],
                    "type": "Content",
                    "abstractKey": "__isContent"
                  },
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Article",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Gallery",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Page",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Podcast",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Recipe",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Video",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UrlPath",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0fb57261b0c2b884dc9ff32757104ac",
    "id": null,
    "metadata": {},
    "name": "digitalArchiveLinkCheckPathQuery",
    "operationKind": "query",
    "text": "query digitalArchiveLinkCheckPathQuery(\n  $url: String!\n  $imageSize: String!\n) {\n  checkPath(url: $url) {\n    __typename\n    ...digitalArchiveLinkImageUrlPath\n    ...digitalArchiveLinkUpdatedDateUrlPath\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment digitalArchiveLinkImageUrlPath on UrlPath {\n  item {\n    __typename\n    ... on Content {\n      __isContent: __typename\n      images {\n        title\n        style(filter: {style: $imageSize}) {\n          url\n        }\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n    ... on Article {\n      id\n    }\n    ... on Gallery {\n      id\n    }\n    ... on Page {\n      id\n    }\n    ... on Podcast {\n      id\n    }\n    ... on Recipe {\n      id\n    }\n    ... on Video {\n      id\n    }\n  }\n}\n\nfragment digitalArchiveLinkUpdatedDateUrlPath on UrlPath {\n  item {\n    __typename\n    ... on Content {\n      __isContent: __typename\n      updatedDate\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n    ... on Article {\n      id\n    }\n    ... on Gallery {\n      id\n    }\n    ... on Page {\n      id\n    }\n    ... on Podcast {\n      id\n    }\n    ... on Recipe {\n      id\n    }\n    ... on Video {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b07674b0216af1e288d18ce207f7530b";

export default node;
