import type { NewsletterItem } from "@product/scmp-sdk/dist/types/components/newsletter/types";
import { graphql } from "react-relay";
import { readInlineData } from "relay-runtime";

import type { helpersMassageToNewsletterItemNewsletter$key } from "~/queries/__generated__/helpersMassageToNewsletterItemNewsletter.graphql";

export const massageToNewsletterItem = (
  newsletter_?: helpersMassageToNewsletterItemNewsletter$key | null,
): NewsletterItem | null => {
  const newsletter = readInlineData(
    graphql`
      fragment helpersMassageToNewsletterItemNewsletter on Newsletter @inline {
        entityId
        name
        alternativeName
        description {
          text
        }
        summary {
          text
        }
        homepageDescription {
          text
        }
      }
    `,
    newsletter_ ?? null,
  );
  if (!newsletter) return null;
  return {
    alternativeName: newsletter.alternativeName,
    description: {
      text: newsletter.description?.text ?? "",
    },
    entityId: newsletter.entityId,
    homepageDescription: {
      text: newsletter.homepageDescription?.text ?? "",
    },
    name: newsletter.name,
    summary: {
      text: newsletter.summary?.text ?? "",
    },
  };
};
