import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { ResponsiveVariants } from "~/lib/styles/responsive";
import { setResponsiveVariantStyles } from "~/lib/styles/responsive";

export type StyledProps = {
  $variant:
    | { $height: string; $type: "multimedia" }
    | { $aspectRatio: number; $type: "video" }
    | { $aspectRatio: number; $type: "aspectRatio" }
    | { $blockSize: string; $type: "static" }
    | { $responsiveBlockSize: ResponsiveVariants<string>; $type: "responsive" };
};

export const Container = styled.span<StyledProps>`
  display: grid;

  ${props => {
    const variant = props.$variant;

    switch (variant.$type) {
      case "aspectRatio":
        return css`
          inline-size: 100%;
          block-size: auto;
          aspect-ratio: ${variant.$aspectRatio};

          /* set style for portrait */
          ${variant.$aspectRatio < 1 &&
          css`
            place-self: center;

            max-inline-size: 476px;
          `}
        `;
      case "responsive":
        return css`
          inline-size: 100%;
          ${setResponsiveVariantStyles(
            variant.$responsiveBlockSize,
            blockSize => css`
              block-size: ${blockSize};
            `,
          )}
        `;
      case "static":
        return css`
          inline-size: 100%;
          block-size: ${variant.$blockSize};
        `;
      case "video":
        return css`
          aspect-ratio: ${variant.$aspectRatio};

          ${variant.$aspectRatio < 1
            ? css`
                inline-size: 476px;
                block-size: auto;
                margin-inline: auto;
              `
            : css`
                inline-size: 100%;
                block-size: auto;
              `}
        `;
    }
  }}
`;

export const StyledIframe = styled.iframe<StyledProps>`
  inline-size: 100%;
  block-size: 100%;
`;
