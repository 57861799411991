import styled from "@emotion/styled";
import type { FunctionComponent } from "react";

import type { ContentSchemaRenderProps } from "~/components/schema-render/content";

import { Caption, StyledContainer, StyledSummary, StyledTable, TableContainer } from "./styles";
import { getTableCaptionsAndFilteredChildren } from "./utils";

export type Props = {
  summary?: string;
} & ContentSchemaRenderProps;

const Component: FunctionComponent<Props> = ({ children, ...attribs }) => {
  const [captions, filteredChildren] = getTableCaptionsAndFilteredChildren(children);

  const summary = attribs?.summary;
  return (
    <StyledContainer>
      {summary ? <StyledSummary>{summary}</StyledSummary> : null}
      {captions ? <Caption>{captions}</Caption> : null}
      <TableContainer>
        <StyledTable>{filteredChildren}</StyledTable>
      </TableContainer>
    </StyledContainer>
  );
};

Component.displayName = "Table";
export const Table = styled(Component)``;
