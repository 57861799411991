import styled from "@emotion/styled";

import { BaseLink } from "~/components/common/base-link";

export const StyledBaseLink = styled(BaseLink)`
  display: inline;

  word-break: break-word;
`;

export const Text = styled.span``;
