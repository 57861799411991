import {
  ArticleThemeSignUpButtonDisplay,
  ArticleThemeTitle,
  NewsletterVariant,
  notEmpty,
} from "@product/scmp-sdk";
import type { NewsletterWidgetProps } from "@product/scmp-sdk";
import first from "lodash/first";
import unionBy from "lodash/unionBy";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { massageToNewsletterItem } from "~/components/newsletter/helpers";
import { NewsletterWidgetContainer } from "~/components/newsletter/newsletter-widget-container";
import { useNewsletterWidget } from "~/components/newsletter/newsletter-widget-container/hooks";
import { newsletter } from "~/data";
import type { contentNewsletterWidgetContent$key } from "~/queries/__generated__/contentNewsletterWidgetContent.graphql";

type Props = {
  className?: string;
  reference: contentNewsletterWidgetContent$key;
} & Partial<NewsletterWidgetProps>;

export const ContentNewsletterWidget: FunctionComponent<Props> = ({
  reference: reference_,
  signUpButtonDisplayComponent = <ArticleThemeSignUpButtonDisplay />,
  titleComponent = <ArticleThemeTitle />,
  variant = NewsletterVariant.Article,
  ...props
}) => {
  const content = useFragment(
    graphql`
      fragment contentNewsletterWidgetContent on Content {
        topics {
          relatedNewsletters {
            ...helpersMassageToNewsletterItemNewsletter
          }
        }
        sections {
          value {
            relatedNewsletters {
              ...helpersMassageToNewsletterItemNewsletter
            }
          }
        }
        ... on Article {
          relatedNewsletters {
            ...helpersMassageToNewsletterItemNewsletter
          }
        }
      }
    `,
    reference_,
  );

  const articleNewsletters = content?.relatedNewsletters?.map(newsletter =>
    massageToNewsletterItem(newsletter),
  );

  // Get the sub section newsletter by reverse the ordering first
  const sectionNewsletters = first(content?.sections)
    ?.value?.map(section => section)
    .reverse()
    .flatMap(section => section?.relatedNewsletters)
    .map(newsletter => massageToNewsletterItem(newsletter));

  const topicsNewsletters = content?.topics
    ?.flatMap(topic => topic?.relatedNewsletters)
    .map(newsletter => massageToNewsletterItem(newsletter));

  // The sequence of the newsletters is
  // 1: Article Newsletters
  // 2: Topics Newsletters
  // 3: Subsection Newsletters
  // 4: Section Newsletters
  const mergedNewsletters = unionBy(
    articleNewsletters,
    topicsNewsletters,
    sectionNewsletters,
    newsletter => newsletter?.entityId,
  ).filter(notEmpty);

  const { dataMatrix } = useNewsletterWidget({
    contentType: "article",
    eventCategory: "Newsletter (Article - Inline)",
    section: "",
  });

  return (
    <NewsletterWidgetContainer
      {...props}
      dataMatrix={dataMatrix}
      newsletterId={newsletter.globalImpact.entityId}
      newsletterItems={mergedNewsletters}
      queryParametersForSocialButtons={{}}
      signUpButtonDisplayComponent={signUpButtonDisplayComponent}
      titleComponent={titleComponent}
      variant={variant}
    />
  );
};

ContentNewsletterWidget.displayName = "ContentNewsletterWidget";
