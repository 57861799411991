import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import { BaseImage } from "~/components/common/base-image";
import { setFullViewportWidthStyles } from "~/lib/styles";
import type { ResponsiveVariants } from "~/lib/styles/responsive";
import { setResponsiveVariantStyles } from "~/lib/styles/responsive";

export const ImageContainer = styled.div``;

export const StyledImage = styled(BaseImage)`
  cursor: pointer;
`;

export const DescriptionContainer = styled.figcaption`
  margin-block-start: 12px;
  padding-inline: 14px;

  color: #6f6f6f;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 20px;
`;

export type StyledProps = {
  $responsiveDescriptionVariants: ResponsiveVariants<"show" | "hidden">;
  $responsiveImageVariants: ResponsiveVariants<"normal" | "square">;
};
export const ArticleImageContainer = styled.figure<StyledProps>`
  ${props => props.theme.breakpoints.only("mobile")} {
    ${setFullViewportWidthStyles()}
    && {
      ${StyledImage}, ${DescriptionContainer} {
        inline-size: 100%;
      }
    }
  }

  ${props =>
    setResponsiveVariantStyles(props.$responsiveDescriptionVariants, variant => {
      switch (variant) {
        case "show":
          return css`
            ${DescriptionContainer} {
              display: block;
            }
          `;
        case "hidden":
          return css`
            ${DescriptionContainer} {
              display: none;
            }
          `;
      }
    })}
  ${props =>
    setResponsiveVariantStyles(props.$responsiveImageVariants, variant => {
      switch (variant) {
        case "normal":
          return css`
            ${ImageContainer} {
              aspect-ratio: auto;
            }
          `;
        case "square":
          return css`
            ${ImageContainer} {
              aspect-ratio: 1;
            }
          `;
      }
    })}
`;
