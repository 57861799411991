import first from "lodash/first";
import type { FunctionComponent } from "react";
import type { PreloadedQuery } from "react-relay";
import { graphql, useFragment, usePreloadedQuery } from "react-relay";

import { BaseImage } from "~/components/common/base-image";
import { query } from "~/components/schema-render/common/link/digital-archive-link";
import type { digitalArchiveLinkCheckPathQuery } from "~/queries/__generated__/digitalArchiveLinkCheckPathQuery.graphql";
import type { digitalArchiveLinkImageUrlPath$key } from "~/queries/__generated__/digitalArchiveLinkImageUrlPath.graphql";

export type Props = {
  lazyload?: boolean;
  queryReference: PreloadedQuery<digitalArchiveLinkCheckPathQuery>;
};

export const DigitalArchiveLinkImage: FunctionComponent<Props> = ({ lazyload, queryReference }) => {
  const content = usePreloadedQuery<digitalArchiveLinkCheckPathQuery>(query, queryReference);

  const data = useFragment<digitalArchiveLinkImageUrlPath$key>(
    graphql`
      fragment digitalArchiveLinkImageUrlPath on UrlPath {
        ... on UrlPath {
          item {
            ... on Content {
              images {
                title
                style(filter: { style: $imageSize }) {
                  url
                }
              }
            }
          }
        }
      }
    `,
    content.checkPath,
  );

  const mainImage = first(data?.item?.images);
  return (
    <>
      {mainImage && (
        <BaseImage
          $aspectRatio="100 / 62"
          $objectFit="cover"
          lazyload={lazyload}
          src={mainImage?.style?.url ?? ""}
          title={mainImage?.title ?? ""}
        />
      )}
    </>
  );
};

DigitalArchiveLinkImage.displayName = "DigitalArchiveLinkImage";
