import type { FunctionComponent } from "react";
import type { PreloadedQuery } from "react-relay";
import { graphql, useFragment, usePreloadedQuery } from "react-relay";

import { query } from "~/components/schema-render/common/link/digital-archive-link";
import { formatInTimeZone } from "~/lib/utils";
import type { digitalArchiveLinkCheckPathQuery } from "~/queries/__generated__/digitalArchiveLinkCheckPathQuery.graphql";
import type { digitalArchiveLinkUpdatedDateUrlPath$key } from "~/queries/__generated__/digitalArchiveLinkUpdatedDateUrlPath.graphql";

export type Props = {
  queryReference: PreloadedQuery<digitalArchiveLinkCheckPathQuery>;
};

export const DigitalArchiveLinkUpdatedDate: FunctionComponent<Props> = ({ queryReference }) => {
  const content = usePreloadedQuery<digitalArchiveLinkCheckPathQuery>(query, queryReference);

  const data = useFragment<digitalArchiveLinkUpdatedDateUrlPath$key>(
    graphql`
      fragment digitalArchiveLinkUpdatedDateUrlPath on UrlPath {
        ... on UrlPath {
          item {
            ... on Content {
              updatedDate
            }
          }
        }
      }
    `,
    content.checkPath,
  );

  return <>{data?.item?.updatedDate && formatInTimeZone(data?.item?.updatedDate, "d MMM yyyy")}</>;
};

DigitalArchiveLinkUpdatedDate.displayName = "DigitalArchiveLinkUpdatedDate";
