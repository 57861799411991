import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto } from "@product/scmp-sdk";

export const RootContainer = styled.div`
  display: flex;

  inline-size: 100%;
  padding: 12px;

  border: 1px #d8d8d8;
  border-style: solid none none;
`;

export const LeftContainer = styled.div`
  inline-size: 100%;
`;

export const RightContainer = styled.div``;

export const ImageContainer = styled.div`
  inline-size: 120px;
  margin-block: 2px;
  margin-inline: 12px 0;

  aspect-ratio: 100/62;

  ${props => props.theme.breakpoints.up("desktop")} {
    inline-size: 132px;
  }
`;

export const TitleText = styled.span`
  font-family: ${fontMerriweather};
`;

export const TitleContainer = styled.div`
  color: #222222;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;

  ${props => props.theme.breakpoints.up("desktop")} {
    font-size: 18px;
    line-height: 30px;
  }

  ${TitleText}:hover {
    border-block-end: 1px solid #222222;
  }
`;

export const UpdatedDateContainer = styled.div`
  margin-block-start: 8px;

  color: #aaaaaa;
  font-weight: 400;
  font-size: 12px;
  font-family: ${fontRoboto};
  line-height: 14px;
`;
