import styled from "@emotion/styled";
import type { FunctionComponent } from "react";

import type { ContentSchemaRenderProps } from "~/components/schema-render/content";
import { normalizeJsxAttribute } from "~/lib/utils";

import { StyledDiv, StyledParagraph } from "./styles";

export type Props = {
  isStaticPage?: boolean;
} & ContentSchemaRenderProps;

const Component: FunctionComponent<Props> = ({ children, schemaNode, ...attribs }) => {
  const hasImageOrLinkInChildren = (schemaNode: SchemaNode): boolean =>
    ["img", "a"].includes(schemaNode.type);

  const Component = schemaNode.children?.some(node => hasImageOrLinkInChildren(node))
    ? StyledDiv
    : StyledParagraph;

  return (
    <Component
      datatype={schemaNode.type}
      {...normalizeJsxAttribute(schemaNode.attribs)}
      {...attribs}
    >
      {children}
    </Component>
  );
};

Component.displayName = "Paragraph";
export const Paragraph = styled(Component)``;
