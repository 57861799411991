import styled from "@emotion/styled";
import type { FunctionComponent } from "react";

import type { ContentSchemaRenderProps } from "~/components/schema-render/content";
import type { contentSchemaRenderContent$data } from "~/queries/__generated__/contentSchemaRenderContent.graphql";

import { PhotoGallery } from "./photo-gallery";
import { StyledDiv } from "./styles";

const types = {
  Div: "div",
  PhotoGallery: "photo-gallery",
};

const Component: FunctionComponent<
  ContentSchemaRenderProps & {
    reference?: contentSchemaRenderContent$data;
  }
> = ({ children, ...attribs }) => {
  const renderMap = {
    [types.Div]: <StyledDiv {...attribs}>{children}</StyledDiv>,
    [types.PhotoGallery]: <PhotoGallery {...attribs} />,
  };

  const determineDivType = () => {
    if (attribs.schemaNode.attribs?.class === "scmp-photo-gallery") return types.PhotoGallery;

    return types.Div;
  };

  const componentType = determineDivType();

  return componentType ? renderMap[componentType] : null;
};

Component.displayName = "Div";

export const Div = styled(Component)``;
