import styled from "@emotion/styled";
import { FacebookEmbed, InstagramEmbed } from "@vip30/react-social-media-embed";
import type { FunctionComponent } from "react";
import { useMemo } from "react";

import type { ContentSchemaRenderProps } from "~/components/schema-render/content";

import {
  FacebookContainer,
  InstagramContainer,
  StyledBlockquote,
  StyledTikTokEmbed,
  TikTokContainer,
} from "./styles";
import { BlockquoteTwitter } from "./twitter";

const types = {
  Blockquote: "blockquote",
  Facebook: "facebook",
  Instagram: "instagram",
  TikTok: "tikTok",
  Twitter: "twitter",
};

const Component: FunctionComponent<ContentSchemaRenderProps> = ({ children, ...attribs }) => {
  const url =
    attribs.schemaNode?.attribs?.["cite"] ??
    attribs.schemaNode?.attribs?.["data-instgrm-permalink"] /* cspell: disable-line */ ??
    "";

  const blockquoteType = useMemo(() => {
    switch (attribs.schemaNode.attribs?.class) {
      case "twitter-tweet":
        return types.Twitter;
      case "tiktok-embed":
        return types.TikTok;
      case "fb-xfbml-parse-ignore":
        return types.Facebook;
    }
    if (attribs.schemaNode.subtype === ("instagram" as SchemaSubtype)) {
      return types.Instagram;
    }
    return types.Blockquote;
  }, [attribs.schemaNode.attribs?.class, attribs.schemaNode.subtype]);

  const componentMap = {
    [types.Blockquote]: <StyledBlockquote {...attribs}>{children}</StyledBlockquote>,
    [types.Twitter]: <BlockquoteTwitter {...attribs} />,
    [types.TikTok]: url && (
      <TikTokContainer>
        <StyledTikTokEmbed url={url} placeholderDisabled retryDisabled />
      </TikTokContainer>
    ),
    [types.Instagram]: url && (
      <InstagramContainer>
        <InstagramEmbed url={url} width={326} placeholderDisabled />
      </InstagramContainer>
    ),
    [types.Facebook]: url && (
      <FacebookContainer>
        <FacebookEmbed url={url} placeholderDisabled />
      </FacebookContainer>
    ),
  };

  return componentMap[blockquoteType];
};

Component.displayName = "Blockquote";

export const Blockquote = styled(Component)``;
