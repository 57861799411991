/**
 * @generated SignedSource<<dbd9101a9a952940c681ad9c93e2a590>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type scmpYoutubeContent$data = {
  readonly publishedDate: number | null | undefined;
  readonly urlAlias: string;
  readonly " $fragmentType": "scmpYoutubeContent";
};
export type scmpYoutubeContent$key = {
  readonly " $data"?: scmpYoutubeContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"scmpYoutubeContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "scmpYoutubeContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedDate",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "c71707bd98f23d726e7b602029a4a87a";

export default node;
