import styled from "@emotion/styled";
import { TwitterEmbed } from "@vip30/react-social-media-embed";
import type { FunctionComponent } from "react";
import { useCallback, useMemo } from "react";

import type { ContentSchemaRenderProps } from "~/components/schema-render/content";

import { TweetContainer } from "./styles";

const Component: FunctionComponent<ContentSchemaRenderProps> = ({ schemaNode }) => {
  const hasPictureLink = useCallback((node: SchemaNode): boolean => {
    if (node.data?.startsWith("pic.twitter.com/")) {
      return true;
    }
    if (node.children) {
      return node.children.some(child => hasPictureLink(child));
    }
    return false;
  }, []);

  const getTweetUrl = useCallback((node: SchemaNode): string => {
    if (node.subtype === ("twitter" as SchemaSubtype)) {
      return node.attribs?.href ?? "";
    }
    if (node.children) {
      for (const [_, child] of node.children.entries()) {
        const url = getTweetUrl(child);
        if (url) return url;
      }
    }
    return "";
  }, []);

  const tweetHasMedia = useMemo(() => hasPictureLink(schemaNode), [hasPictureLink, schemaNode]);
  const tweetUrl = useMemo(() => getTweetUrl(schemaNode), [getTweetUrl, schemaNode]);

  return (
    tweetUrl && (
      <TweetContainer $hasMedia={tweetHasMedia}>
        <TwitterEmbed url={tweetUrl} placeholderDisabled />
      </TweetContainer>
    )
  );
};

Component.displayName = "BlockquoteTwitter";

export const BlockquoteTwitter = styled(Component)``;
