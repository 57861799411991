import type { ReactNode } from "react";
import { Children, isValidElement } from "react";

import { StyledCaption } from "~/components/schema-render/content/styles";

export const getTableCaptionsAndFilteredChildren = (children: ReactNode) =>
  Children.toArray(children)?.reduce<[JSX.Element[], JSX.Element[]]>(
    ([captionComponents, childrenComponents], child) => {
      if (!isValidElement(child)) return [captionComponents, childrenComponents];
      if (child.type === StyledCaption) captionComponents.push(child);
      else childrenComponents.push(child);
      return [captionComponents, childrenComponents];
    },
    [[], []],
  );
