export const useContentUtils = () => {
  /**
   * Extract text from schema node to string
   */
  const extractTextFromSchemaNodes = (item: SchemaNode | SchemaNode[]) => {
    const array = Array.isArray(item) ? item : [item];
    return array.reduce((output, current) => {
      if (current?.type === "text" && current?.data && current?.data !== "\n") {
        output += current.data.trim();
      }
      if (current.children) {
        output += current.children.map(child => extractTextFromSchemaNodes(child)).join(" ");
      }

      return output;
    }, "");
  };

  return {
    extractTextFromSchemaNodes,
  };
};
