/**
 * @generated SignedSource<<ea3f895b54a397dc46685f197e88f6be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type digitalArchiveLinkImageUrlPath$data = {
  readonly item: {
    readonly images?: ReadonlyArray<{
      readonly style: {
        readonly url: string | null | undefined;
      } | null | undefined;
      readonly title: string | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "digitalArchiveLinkImageUrlPath";
};
export type digitalArchiveLinkImageUrlPath$key = {
  readonly " $data"?: digitalArchiveLinkImageUrlPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"digitalArchiveLinkImageUrlPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "imageSize"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "digitalArchiveLinkImageUrlPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "images",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "fields": [
                        {
                          "kind": "Variable",
                          "name": "style",
                          "variableName": "imageSize"
                        }
                      ],
                      "kind": "ObjectValue",
                      "name": "filter"
                    }
                  ],
                  "concreteType": "ImageStyle",
                  "kind": "LinkedField",
                  "name": "style",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UrlPath",
  "abstractKey": null
};

(node as any).hash = "01639027eaf237dcb2289710667ee917";

export default node;
