import styled from "@emotion/styled";
import { PageType } from "@product/scmp-sdk";
import { useLatest } from "ahooks";
import classNames from "classnames";
import { type FunctionComponent, useCallback } from "react";

import type { Props as BaseLinkProps } from "~/components/common/base-link";
import { BaseLink } from "~/components/common/base-link";
import type { ContentSchemaRenderProps } from "~/components/schema-render/content";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { data as appData, config, tracking } from "~/data";
import { useCurrentPageType } from "~/lib/router/hooks";
import { isAbsoluteUrl } from "~/lib/utils";
import type { contentSchemaRenderContent$data } from "~/queries/__generated__/contentSchemaRenderContent.graphql";

import type { DigitalArchiveLinkProps } from "./digital-archive-link";
import { DigitalArchiveLink } from "./digital-archive-link";
import { AnchorRelationship, AnchorTarget } from "./enums";
import { StyledBaseLink, Text } from "./styles";

type GetQueryParametersModuleParameters = {
  isDrupalAutoLink?: boolean;
  isShowDigitalArchiveLink: boolean;
  pageType: PageType;
};

const getQueryParametersModule = (parameters: GetQueryParametersModuleParameters) => {
  const { isDrupalAutoLink, isShowDigitalArchiveLink, pageType } = parameters;

  if (isShowDigitalArchiveLink) {
    return pageType === PageType.PostiesArticle
      ? tracking.module.HardLinkPosties
      : tracking.module.HardLink;
  }

  if (isDrupalAutoLink) {
    return tracking.module.InlineAuto;
  }

  return pageType === PageType.PostiesArticle
    ? tracking.module.InlinePosties
    : tracking.module.Inline;
};

export type ExtraProps = {
  digitalArchiveLinkComponentRenderFunction?: (props: DigitalArchiveLinkProps) => JSX.Element;
};

export type Props = {
  className?: string[];
  digitalArchiveLinkComponentRenderFunction?: (props: DigitalArchiveLinkProps) => JSX.Element;
  href?: string;
  reference?: contentSchemaRenderContent$data;
} & ContentSchemaRenderProps &
  ExtraProps;

const Component: FunctionComponent<Props> = ({
  children,
  className,
  digitalArchiveLinkComponentRenderFunction,
  href = "",
  parentSchemaNode,
  reference,
  schemaNode: schemaNode,
  ...attribs
}) => {
  // Link added in drupal by system config automatically
  const isDrupalAutoLink = className?.includes("d8-auto-link");
  const regex = new RegExp("(^https?://)((www.)?scmp.com|.*.product-web.dev-2.scmp.tech)");
  const isInternalScmpLink = regex.test(href);
  const isShowDigitalArchiveLink =
    !isDrupalAutoLink &&
    ["p", "h3"].includes(parentSchemaNode?.type ?? "") &&
    parentSchemaNode?.children?.length === 1 &&
    parentSchemaNode?.children?.[0].type === "a" &&
    isInternalScmpLink;

  const currentPageType = useCurrentPageType();
  // Generate link with custom parameters
  const queryParameters: BaseLinkProps["query"] = {
    module: getQueryParametersModule({
      isDrupalAutoLink,
      isShowDigitalArchiveLink,
      pageType: currentPageType,
    }),
    pgtype: currentPageType,
  };

  const isInternalUrl =
    isInternalScmpLink ||
    [
      appData.application.scmp.domain,
      appData.application.goldthread.domain,
      config.general.siteUrl,
    ].some(internalUrl => href.includes(internalUrl));
  let anchorTarget: OrUndefined<AnchorTarget>;
  let anchorRelationship: OrUndefined<string>;
  if (!isAbsoluteUrl(href) || !isInternalUrl || className?.includes("js-tracking-referral")) {
    if (!isInternalUrl) anchorRelationship = AnchorRelationship.NoFollow;
    anchorTarget = AnchorTarget.Blank;
  }

  const handleGATracking = () => {
    sendGATracking({
      action: isShowDigitalArchiveLink ? "Hard link/Click" : "Inline link/Click",
      category: "Article Inline Link",
      label: href,
    });
  };

  const BaseLinkAttributes = {
    ...attribs,
    anchorProps: { rel: anchorRelationship, target: anchorTarget },
    onClick: handleGATracking,
    pathname: href,
    query: isInternalUrl ? queryParameters : {},
  };

  const latestDigitalArchiveLinkComponentRenderFunction = useLatest(
    digitalArchiveLinkComponentRenderFunction,
  );
  const handleRenderDigitalArchiveLink = useCallback(
    (props: DigitalArchiveLinkProps) =>
      latestDigitalArchiveLinkComponentRenderFunction?.current ? (
        latestDigitalArchiveLinkComponentRenderFunction?.current({ ...attribs, ...props })
      ) : (
        <DigitalArchiveLink
          {...{
            ...attribs,
            ...props,
            reference,
          }}
        />
      ),
    [attribs, latestDigitalArchiveLinkComponentRenderFunction, reference],
  );

  return isShowDigitalArchiveLink ? (
    // Note: passed style instead of using emotion because need to keep className
    //       clean for the GTM events
    <BaseLink style={{ inlineSize: "100%" }} {...BaseLinkAttributes} className="link-text">
      {handleRenderDigitalArchiveLink({
        href,
        index: attribs.index,
        schemaNode,
      })}
    </BaseLink>
  ) : (
    <StyledBaseLink {...BaseLinkAttributes} className={classNames(className)}>
      <Text> {children}</Text>
    </StyledBaseLink>
  );
};

Component.displayName = "Link";
export const Link = styled(Component)``;
